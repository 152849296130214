<template>
  <div class="form-stepper form-stepper-feed">
    <Loading :value="loading"/>
    <v-form
      ref="form"
      v-model="valid"
      class="form pa-6"
    >

      <div class="d-flex pb-4 mt-4 align-center" v-if="channelItem.id">
        <p class="text-h6 mb-0"><span>{{$t('CHANNEL_SUBSCRIPTION.STEP_CHANNEL_TITLE')}}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 0)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <v-divider />
        <v-row class="mt-0 mb-0 text-medium" v-if="values.channel_id">
          <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
            {{$t('CHANNEL_SUBSCRIPTION.TITLE')}}
          </v-col>
          <v-col cols="6" class="text-ellipsis text-nowrap">
            <span>{{channelItem.title}}</span>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex pb-4 mt-10 align-center">
        <p class="text-h6 mb-0"><span>{{$t('CHANNEL_SUBSCRIPTION.STEP_PARAMS_TITLE')}}</span></p>
        <v-btn
          x-small
          depressed
          rounded
          color="primary"
          class="ml-4"
          @click="$emit('step', 1)"
        >
          {{ $t('COMMON.MODIFY') }}
        </v-btn>
      </div>
      <div>
        <template v-for="(key, i) in Object.keys(values.account_params)">
          <div :key="'fir'+i">
            <v-divider />
            <v-row class="mt-0 mb-0 text-medium">
              <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                {{$t('INPUT.' + key.toUpperCase())}}
              </v-col>
              <v-col cols="10" class="text-ellipsis text-nowrap">
                <span>{{ values.account_params[key] }}</span>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>
      <div>
        <template v-for="(key, i) in Object.keys(values.channel_params)">
          <div :key="'fir'+i">
            <v-divider />
            <v-row class="mt-0 mb-0 text-medium">
              <v-col cols="2" class="text-ellipsis text-nowrap grey--text">
                {{$t('INPUT.' + key.toUpperCase())}}
              </v-col>
              <v-col cols="10" class="text-ellipsis text-nowrap">
                <span>{{ values.channel_params[key] }}</span>
              </v-col>
            </v-row>
          </div>
        </template>
      </div>


    </v-form>
    <v-divider/>

    <v-toolbar
      color="transparent"
      flat
      height="70px"
      class="form-footer"
    >
      <v-spacer/>

      <v-btn
        color="success"
        class="mr-4"
        rounded
        depressed
        :disabled="!valid && !loading"
        large
        @click="validate"
      >
        <v-icon class="mr-2">mdi-check</v-icon>
        <strong>{{ $t('SAVE') }}</strong>
      </v-btn>
    </v-toolbar>

  </div>
</template>

<script>
import Loading from '@/components/Loading.vue'

export default {
  name: "FormFeedStepperConfirm",
  components: {
    Loading,
  },
  props: {
    values: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    channelItem: {},
    loading: false,
    valid: true,
  }),
  mounted () {
    this.loadChannelItem()
  },
  methods: {

    loadChannelItem () {
      this.loading = true
      return this.$services.api_programmatic.channel
        .search({where:{id:this.values.channel_id}})
        .then(response => {
          if (response.data && response.data.length > 0) {
            this.channelItem = response.data[0]
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    async validate() {
      if (!this.$refs.form.validate()) return

      const values = this.$clone(this.values)

      // prepare contract
      const type = this.channelItem.methods[0] === 'credit' ? 'credit' : 'budget'
      const contract = {
        no_limit_time: true,
        no_limit_value: true,
        type,
        methods: this.channelItem.methods,
        prices: this.channelItem.prices
      }

      this.loading = true
      try {
        const response = await this.$services.api_programmatic.channel_subscription.create({values})
        // create défault contract
        contract.channel_subscription_id = response.data.id
        await this.$services.api_programmatic.channel_subscription_contract.create({values:contract})
        this.$emit('validation', true)
        this.$emit('create', response.data[1])
        this.$emit('close')
        if (response.data) {
          this.values = {}
          this.$emit('step', 0)
        }
      }
      catch(e) {
        this.$emit('validation', false)
        await this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
      }
      finally {
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>

</style>
